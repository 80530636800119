<template>
  <lkps-table
    :api-endpoint="apiEndpoint"
    :headers="headers"
    :form="form"
    single-export="Dapat Ditinjau"
  />
</template>

<script>
import LkpsTable from '@views/pages/components/LKPSTable.vue'

export default {
  components: {
    LkpsTable,
  },
  data() {
    return {
      apiEndpoint: 'lkps_kerjasama',
      lkps_table_id: parseInt(this.$route.params.lkps_table_id),
      headers: [
        [{
          label: 'Nama Lembaga Mitra',
          name: 'lembaga_mitra',
          dataKey: 'lembaga_mitra',
          order: 1,
          attributes: { rowspan: 2 },
        }, {
          label: 'Tingkat',
          attributes: { colspan: 3 }
        }, {
          label: 'Judul dan Ruang Lingkup Kerjasama',
          name: 'judul_kegiatan',
          dataKey: 'judul_kegiatan',
          order: 5,
          attributes: { rowspan: 2 },
        }, {
          label: 'Manfaat / Output',
          name: 'manfaat_bagi_ps',
          dataKey: 'manfaat_bagi_ps',
          order: 6,
          attributes: { rowspan: 2 },
        }, {
          label: 'Durasi',
          name: 'wkt_durasi',
          dataKey: 'wkt_durasi',
          order: 7,
          attributes: { rowspan: 2 },
        }, {
          label: 'Bukti / Tautan',
          name: 'bukti',
          dataKey: 'bukti',
          order: 8,
          attributes: { rowspan: 2 },
        }],
        [{
          label: 'Internasional',
          name: 'ting_internation',
          dataKey: 'ting_internation',
          order: 2,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Nasional',
          name: 'ting_nasional',
          dataKey: 'ting_nasional',
          order: 3,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }, {
          label: 'Lokal',
          name: 'ting_wilayah',
          dataKey: 'ting_wilayah',
          order: 4,
          dataAttributes: { class: 'text-center align-middle' },
          render(data) {
            return data ? '✓' : ''
          }
        }]
      ],
      form: {
        inputs: [{
          label: 'Nama Lembaga',
          name: 'lembaga_mitra',
          dataKey: 'lembaga_mitra',
          type: 'text',
          
        }, {
          label: 'Internasional',
          name: 'ting_internation',
          dataKey(data) {
            return data.ting_internation ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
        }, {
          label: 'Nasional',
          name: 'ting_nasional',
          dataKey(data) {
            return data.ting_nasional ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
        }, {
          label: 'Lokal',
          name: 'ting_wilayah',
          dataKey(data) {
            return data.ting_wilayah ? '✓' : ''
          },
          type: 'select',
          options: ['✓'],
          customSelectedValue(data) {
            return data == '✓' ? 1 : 0
          },
        }, {
          label: 'Judul dan Ruang Lingkup Kerjasama',
          name: 'judul_kegiatan',
          dataKey: 'judul_kegiatan',
          type: 'text',
          
        }, {
          label: 'Manfaat / Output',
          name: 'manfaat_bagi_ps',
          dataKey: 'manfaat_bagi_ps',
          type: 'text',
          
        }, {
          label: 'Durasi',
          name: 'wkt_durasi',
          dataKey: 'wkt_durasi',
          type: 'text',
          
        }, {
          label: 'Bukti / Tautan',
          name: 'bukti',
          dataKey: 'bukti',
          type: 'text',
          
        }],
        customPayload(data) {
          return {
            ...data,
            ting_internation: data.ting_internation ? 1 : 0,
            ting_nasional: data.ting_nasional ? 1 : 0,
            ting_wilayah: data.ting_wilayah ? 1 : 0,
          }
        }
      }
    }
  },
}
</script>
